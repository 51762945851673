<template>
  <v-container fluid>
    <snackbar refs="snackbar"></snackbar>
    <v-row>
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-text-field
          v-if="record"
          outlined
          dense
          label="Key"
          v-model="record.variable_key"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-text-field
          v-if="record"
          outlined
          dense
          label="Value"
          v-model="record.variable_value"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-btn color="accent" @click="sendData" :loading="loader">
          <v-icon left>mdi-content-trash</v-icon>Save
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    initial: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      record: {},
      loader: false,
      loading: false,
    };
  },

  watch: {
    initial: {
      handler: "init",
      immediate: true,
    },
  },

  methods: {
    init(val) {
      // console.log(val, "initial");
      this.loading = false;
      this.record = { ...val };
    },
    sendData() {
      let data = this.record;
      // console.log(data);
      const self = this;
      self.loader = false;
      this.$store.commit("loader", true);
      this.$emit("data", data, () => {
        self.$store.commit("loader", false);
      });
    },
  },
};
</script>
